import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import userhistory from "../Components/data/userhistory.js";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { getUserHistory } from "../actions/admin";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi.js";
function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i <= pages; i++) {
    results.push(i);
  }

  return results;
}

const columns = [
  // {
  //   name: "S no",
  //   selector: (row) => row,
  //   sortable: true,
  // },
  {
    name: "S.no",
    selector: (row,index) => index + 1,
     sortable: true,
      minWidth:"210px"
    // sortable: true,
  },
  {
    name: "User Id",
    selector: (row) => row.userId,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.firstName,
    sortable: true,
  },
  {
    name: "Account Status",
    selector: (row) => (row.Deleted == 0 ? "Active" : "Deactive"),
    sortable: true,
  },
  {
    name: "Account Type",
    selector: (row) => row.AccountStatus,
    sortable: true,
  },
  {
    name: "Followers",
    selector: (row) => row.Followers,
    sortable: true,
  },
  {
    name: "Followings",
    selector: (row) => row.Followings,
    sortable: true,
  },
  {
    name: "No Of Posts",
    selector: (row) => row.PostCount.PostCount,
    sortable: true,
  },
];

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav className="d-flex justify-content-end mt-3">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page">
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}>
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page">
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

// import ReactDatatable from "@ashvin27/react-datatable";

function Userhistory() {
  // console.log(state.records[0].status.props,"asdfasfasdfasdf");

  const [userHistory, setUserHistory] = useState();

  const getUserAllHistory = async () => {
    try {
      let reqData = { method: "post" };
      let data = encrypt(reqData)
      let { status, message, result, count } = await getUserHistory(data);
      if (status == "success") {
        setUserHistory(result);
      }
    } catch {}
  };

  useEffect(() => {
    getUserAllHistory();
  }, []);

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"User History"} />

            <div className="common_page_scroller mt-5 pe-2">
              <div className="exchange_table_holder users_table dashboard_box rounded-3 mt-4 p-3">
                <DataTable
                  columns={columns}
                  data={userHistory}
                  defaultSortFieldID={1}
                  pagination
                  paginationComponent={BootyPagination}
                />

                {/* <ReactDatatable 
                  config={config}
                  records={state}
                  columns={columns}
                  extraButtons={extraButtons}
                /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Userhistory;
