const nav = [
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/grid.svg").default,
  },
  {
    path: "/userlist",
    name: "User List",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/exchange.svg").default,
  },
  {
    path: "/userhistory",
    name: "User History",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/user_history.svg").default,
  },
  {
    path: "/subAdmin",
    name: "Sub Admin",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/subadmin.svg").default,
  },

  // {
  //   path: "/faqSettings",
  //   name: "FAQ Settings",
  //   exact: true,
  //   sidemenu: true,
  //   type: "private",
  //   image: require("../assets/images/create.svg").default,
  // },
  {
    path: "/cmslist",
    name: "Cms Settings",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/create.svg").default,
  },
  {
    path: "/siteSettings",
    name: "Site Settings",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/editer.svg").default,
  },

  {
    path: "/tip",
    name: "Tip",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/Tips.svg").default,
  },
  {
    path: "/donation",
    name: "Donation",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/donate.svg").default,
  },

  {
    path: "/resetPassword",
    name: "Admin Settings",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/userSetting.svg").default,
  },


  {
    path: "/supportTicket",
    name: "Support Ticket",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/ticket.svg").default,
  },

  {
    path: "/profile",
    name: "My Profile",
    exact: true,
    sidemenu: true,
    type: "private",
    image: require("../assets/images/user.svg").default,
  },
];

export default nav;
