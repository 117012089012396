import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import subadmin from "../Components/data/subadmin.js";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import DataTable from "react-data-table-component";
import DeactivateModal from "../Modals/DeactivateModal.js";
import { getAdminList } from "../actions/admin";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi.js";

function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i <= pages; i++) {
    results.push(i);
  }

  return results;
}

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav className="d-flex justify-content-end mt-3">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page">
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}>
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page">
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

// import ReactDatatable from "@ashvin27/react-datatable";

function Subadmin() {
  const [adminList, setAdminList] = useState([]);

  const getSubadminList = async () => {
    try {
      let reqData = { method : "get" };
      let data = encrypt(reqData);
      let { status, message, result } = await getAdminList(data);
      console.log(
        "🚀 ~ getSubadminList ~ status, message, result :",
        status,
        message,
        result
      );
      if (status == "success") {
        setAdminList(result);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getSubadminList();
  }, []);
console.log("adminList_userdata", adminList);
  // console.log(state.records[0].status.props,"asdfasfasdfasdf");
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [adminstatus, setStatus] = useState();

  const [deleteRecord, setDeleteRecord] = useState({});

  const handleShowDeactivate = (record) => {
    setDeleteRecord(record);
    setShowDeactivate(true);
  };

  const handleCloseDeactivate = (record) => {
    setDeleteRecord({});
    setShowDeactivate(false);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email, 
      sortable: true,
    },
    {
      name: "Role Name",
      selector: (row) => row?.role,
      sortable: true,
    },
    {
      name: "Role Access",
      selector: (row) => row?.restriction?.length,
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <div className="App">
          <div class="openbtn text-center">
            <Link
              to={{
                pathname: "/editsubAdmin",
                state: { datas: JSON.stringify(row) },
              }}
              class={"btn subs_table_greenBtn me-2"}>
              Edit
            </Link>
            <button
              type="button"
              class={
                row.status ? "btn subs_table_redBtn" : "btn subs_table_greenBtn"
              }
              // onClick={() => handleShowDeactivate(row)}>
              onClick={() => {
                handleShowDeactivate(row._id);
                setStatus(row.status ? "Deactivate" : "Activate");
              }}>
              {row.status ? "Deactivate" : "Activate"}
            </button>
          </div>
        </div>
      ),

      // cell: (row) => (
      //   <div className="App">
      //     <div class="openbtn text-center">
      //       <Link to="/editsubAdmin" class={"btn subs_table_greenBtn"}>
      //         Edit
      //       </Link>
      //       <button
      //         type="button"
      //         class={
      //           row.status ? "btn subs_table_greenBtn" : "btn subs_table_redBtn"
      //         }
      //         onClick={() => {
      //           handleShowDeactivate(row._id);
      //           setStatus(row.status ? "Deactivate" : "Activate");
      //         }}>
      //         {row.status ? "Activate" : "Deactivate"}
      //       </button>
      //     </div>
      //   </div>
      // ),
    },
  ];

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Sub Admin"} />

            <div className="common_page_scroller mt-5 pe-2">
              <div className="text-end">
                <Link to="/addsubAdmin" className="orange_small_primary">
                  Add
                </Link>
              </div>
              <div className="exchange_table_holder subadmin_table dashboard_box rounded-3 mt-4 p-3">
                <DataTable
                  columns={columns}
                  data={adminList}
                  defaultSortFieldID={1}
                  pagination
                  paginationComponent={BootyPagination}
                />

                {/* <ReactDatatable 
                  config={config}
                  records={state}
                  columns={columns}
                  extraButtons={extraButtons}
                /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {showDeactivate && (
        <DeactivateModal
          show={showDeactivate}
          adminstatus={adminstatus}
          record={deleteRecord}
          getSubadminList={getSubadminList}
          handleClose={handleCloseDeactivate}
        />
      )}
    </>
  );
}

export default Subadmin;
