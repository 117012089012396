import React from 'react'
import {Modal} from 'react-bootstrap';



function WalletconnectModal({show,handleClose}) {
  return (
   <>
   <Modal centered className='cmn_modal'
    size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
            <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title'>Connect Wallet</p>
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>

            <div className='wallet_sec'>
            
            <div className={`walletrow text-center`} onClick={handleClose}>
            <img src={require('../assets/images/metamask.png')} alt='metamask' className={`img-fluid walletimg`} />
            <p className={`label_wallet mt-3`}>Metamask</p>
          </div>
          <div className={`walletrow text-center`} onClick={handleClose}>
            <img src={require('../assets/images/walletconnect.png')} alt='metamask' className={`img-fluid walletimg`} />
            <p className={`label_wallet mt-3`}>Walletconnect</p>
          </div>
          </div>
         
        </Modal.Body>
       
      </Modal>

   </>
  )
}

export default WalletconnectModal