import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import OTPInput from "react-otp-input";
import { verifyOtp } from "../actions/admin";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi";

const initialFormValue = {
  otp: "",
};
function OtpVerification() {
  const [otp, setOtp] = useState("");
  let regex = /^[0-9]+$/;

  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const location = useLocation();

  const validation = (value) => {
    try {
      console.log("valuevalue", value)
      let error = { ...errors };
      if (isEmpty(value.otp)) {
        console.log(isEmpty(value.name), "nameeeeee");
        error["otp"] = "Please Fill OTP";
      } else {
        error["otp"] = "";
      }
      console.log("err_Errors", error)
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      console.log("otp data", otp)
      let isValidate = validation({ otp: otp });
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let reqData = {
        email: JSON.parse(location.state.datas),
        otp: otp, method : "post"
      };
      let data = encrypt(reqData);
      let { status, message, result } = await verifyOtp(data);
      console.log("status, message, result", status, message, result)
      if (status == "success") {
        // history.push("/resetNewPassword");
        toast.success("OTP Verified Successfully");
        history.push({ 
          pathname: "/resetNewPassword",
          state: { datas: JSON.parse(location.state.datas) },
        });
        setErrors({});
      } else if(status == "failed") {
        toast.error(message);
      } else {
        toast.error("OTP Verified Failed");
        // history.push("/forgotpassword");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg login_bg position-relative">
        <div className="logo_sec">
          <img
            src={require("../assets/images/headerlogo.png")}
            className="img-fluid main_logo"
          />
        </div>

        <Row className="justify-content-center align-items-center row_card">
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="dashboard_box p-4">
              <div className="profile_holder">
                <p className="header_title_big">OTP Verification</p>
                <hr className="grey_hr" />
              </div>

              <div className="profile_holder">
                <Row>
                  <Col lg={12} md={12} sm={12} className="mb-2">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label text-center">Enter OTP</p>
                      <div className="optVerification_Input text-center mt-3">
                        <OTPInput
                        value={otp ? (otp == "" || regex.test(otp)) ? otp : (otp == "" || regex.test(otp)) ? otp : "" : ""}
                          onChange={setOtp}
                          numInputs={4}
                          name="otp"
                          className="optVerification_Input"
                          //   renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <span className="text-danger optVerification_Input d-flex justify-content-center">
                        {errors.otp}
                      </span>
                    </div>
                    <div className="mt-3 text-end">
                      <Link to="/" className="link_theme">
                        Back to Login?
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  {/* <button className='orange_small_primary'>Submit</button> */}

                  <Button
                    className="orange_small_primary"
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OtpVerification;
