import React from "react";
import { Modal } from "react-bootstrap";
import { EditAdminStatus } from "../actions/admin";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi";

function DeactivateModal({
  show,
  handleClose,
  adminstatus,
  record,
  getSubadminList,
}) {
  const editAdminStatus = async () => {
    try {
      let userStatus = adminstatus == "Deactivate" ? false : true;
      console.log("🚀 ~ editUserStatus ~ userStatus:", userStatus);
      let reqData = {
        _id: record,
        status: userStatus,
        method : "post"
      };
      console.log("editAdminStatus_data", reqData);
      let data = encrypt(reqData);
      let { status, message, result } = await EditAdminStatus(data);
      if (status == "success") {
        toast.success("User Status Changed");
        getSubadminList();
      } else {
        toast.error("User Status Changed Failed");
        getSubadminList();
      }
    } catch (err) {
      console.log("deleteUser_err", err);
    }
  };
  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">{adminstatus} Subadmin</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-3 pb-2">
            <p className="dash_graymed_text">
              Are you sure want to {adminstatus} this subadmin?
            </p>
            <button
              className="orange_small_primary mt-5"
              onClick={() => {
                editAdminStatus();
                handleClose();
              }}>Confirm</button>
            <button
              className="orange_small_secondary mt-5 ms-3"
              onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeactivateModal;
