import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Select from "react-select";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import nav from "../config/routes";
import { EditAdmin } from "../actions/admin";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import MultiSelectAll from "../Components/MultiSelectAll";
import { encrypt } from "../DecryptApi/decryptapi";

const initialFormValue = {
  name: "",
  email: "",
  password: "",
  role: "subadmin",
  restriction: [],
};

function EditSubadmin() {
  const [pwd, setPwd] = useState(false);
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  // const navigate = useNavigate();
  const history = useHistory();
  const location = useLocation();
  const [selectedOptions, setSelectedOptions] = useState();
  const [options, setOptions] = useState();
  useEffect(() => {
    let FormData = JSON.parse(location.state.datas);
    setFormvalue(FormData);
    console.log('optionsoptionsoptions', options)
    let selectedOption = options?.filter((el) => {
      if (FormData?.restriction.includes(el.value)) {
        return el;
      }
    });
    console.log(
      selectedOption,
      FormData.restriction,
      "selectedOptionselectedOption"
    );
    setSelectedOptions(selectedOption);
  }, [location, options]);
  console.log(
    selectedOptions,
    "selectedOptionselectedOption"
  );
  const setNavOptions = (async) => {
    let data =
      nav &&
      nav.length > 0 &&
      nav.map((item, i) => {
        return {
          label: item.name,
          value: item.path,
        };
      });
    let selectedOption = [];
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].value == "/subAdmin" ||
        data[i].value == "/profile" ||
        data[i].value == "/resetPassword"
      ) {
        continue;
      } else {
        selectedOption.push({
          label: data[i].label,
          value: data[i].value,
        });
      }
    }
    console.log('selectedOption_data', selectedOption)
    setOptions(selectedOption);
  };
  useEffect(() => {
    setNavOptions();
  }, []);

  console.log(
    formvalue?.restriction,
    selectedOptions,
    "formvalue?.selectedOptions"
  );

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.name)) {
        error["name"] = "Name is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.email)) {
        error["email"] = "Email is required";
      } else {
        error["email"] = "";
      }
      if (isEmpty(selectedOptions)) {
        error["restriction"] = "Permissions is required";
      } else {
        error["restriction"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      const { email, name, password, role } = formvalue;
      setSelectedOption1(
        selectedOptions.map((val) => {
          return val.value;
        })
      );
      let oj = {
        _id: formvalue._id,
        email: formvalue.email,
        name: formvalue.name,
        restriction: selectedOption1,
        method : "post"
      };
      let isValidate = validation(oj);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      console.log("selectedOption1_data", selectedOption1)
      let data = encrypt(oj);
      let { status, message, result } = await EditAdmin(data);
      if (status == "success") {
        toast.success("Sub Admin Updated Successfully");
        history.push("/subAdmin");
      } else {
        toast.error("Sub Admin Updated Failed");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#74fdfc"
          : isSelected
          ? "#74fdfc"
          : isFocused
          ? "#74fdfc"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      // height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    // indicatorsContainer: (provided, state) => ({
    //   ...provided,
    //   height: "40px",
    //   position: "absolute",
    //   right: 0,
    //   top: 0,
    //   color: "red",
    //   padding: "0px",
    // }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      // width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  const [selectedOption1, setSelectedOption1] = useState();

  const handleChange1 = (e) => {
    console.log(e, "selectedOptionselectedOption");

    setSelectedOption1(
      e.map((val) => {
        return val.value;
      })
    );
  };
  const options1 = [
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
  ];
  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Edit Subadmin"} />
            <div className="mt-5 profile_holder ps-lg-3 pb-5">
              <Row>
                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Name</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formvalue.name}
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </Col>

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Email</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        readOnly
                        value={formvalue.email}
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                  </div>
                </Col>

                {/* <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Password</p>

                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={pwd ? "text" : "password"}
                        placeholder="Enter Password"
                        value="secret@123"
                        className="rp_singleInput flex-grow-1"
                      />

                      <i
                        class={
                          pwd ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                        }
                        onClick={() => setPwd(!pwd)}
                      />
                    </div>
                  </div>
                </Col> */}

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Role</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Role"
                        value={formvalue.role}
                        readOnly
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Permissions</p>
                    {/* <div className="rp_input_holder rounded-2 py-1 px-0">
                      <Select
                        onChange={(e) => handleChange1(e)}
                        options={options}
                        defaultValue="None"
                        styles={stylesgraybg}
                        isSearchable={false}
                        isMulti
                        classNamePrefix="customselect"
                        value={
                          selectedOption1 && selectedOption1?.length > 0
                            ? options?.filter((el) => {
                                if (selectedOption1.includes(el.value)) {
                                  return el;
                                }
                              })
                            : []
                        }
                        // defaultInputValue="Theme 1"
                        // menuIsOpen
                      />
                    </div> */}
                    <div className="multi_select_all">
                      {/* <ReactMultiSelectCheckboxes
                        options={options}
                        setSelectedOptions={(data) => {
                          setSelectedOptions(data);
                        }}
                        selectedOptions={selectedOptions}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        // options={[{ label: "All", value: "*" }, ...options1]}
                      /> */}

                      <MultiSelectAll
                        options={options}
                        // setSelectedOptions={(data) => {
                        //   setSelectedOptions(data);
                        // }}
                        type={"edit"}
                        setSelectedOptions={setSelectedOptions}
                        selectedOptions={selectedOptions && selectedOptions}
                      />
                    </div>
                    <span className="text-danger">{errors.restriction}</span>
                  </div>
                </Col>
              </Row>
              <button
                className="orange_small_primary"
                onClick={() => {
                  handleSubmit();
                }}>
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EditSubadmin;
