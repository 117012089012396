import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import browser from "browser-detect";

import { UseDispatch, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { login, getGeoInfoData } from "../actions/admin";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi";
const initialFormValue = {
  email: "",
  password: "",
};

function Login() {
  const [errors, setErrors] = useState({});
  const [formvalue, setformvalue] = useState(initialFormValue);
  const [loginHistory, setLoginHistory] = useState({});

  const [pwd, setPwd] = useState(false);

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const history = useHistory();

  const getGeoInfo = async () => {
    try {

      let { result } = await getGeoInfoData();
      console.log("result data", result)

      const browserResult = browser();
      let loginHistory = {
        countryName: result?.country_name,
        countryCode: result?.country_calling_code,
        ipaddress: result?.ip,
        region: result?.region,
        broswername: browserResult?.name,
        ismobile: browserResult?.mobile,
        os: browserResult?.os,
      };
      console.log("loginHistory_data", loginHistory);
      setLoginHistory(loginHistory);
    } catch (err) {
      console.log("getGeoInfo_errr", err)
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      console.log('error validation',value, error)
      if (isEmpty(value.email)) {
        error["email"] = "Email is required";
      } else {
        error["email"] = "";
      }
      if (isEmpty(value.password)) {
        error["password"] = "Password is required";
      } else {
        error["password"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // this.setState({ [e.target.id]: e.target.value });
    if (name == "twoFACode") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
    }
    // this.setState({...this.state.formvalue,...{[name]:value}})
    // this.setState({
    let Formvalue = { ...formvalue, ...{ [name]: value } };
    let error = { ...errors };
    error[`${name}`] = "";
    setformvalue(Formvalue);
    setErrors(error);
    // });
  };

  const onSubmit = async (e) => {
    try {
      const { email, password } = formvalue;
      console.log("🚀 ~ onSubmit ~ email, password:", email, password);
      const reqData = {
        email: email,
        password: password,
        loginHistory: loginHistory,
        method : "post"
      };
      let isValidate = validation(formvalue);
      console.log("🚀 ~ onSubmit ~ isValidate:", isValidate);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      console.log("reqData datasgal", reqData);
      let data = encrypt(reqData);
      const { status, loading, message } = await login(data, dispatch);
      console.log("🚀 ~ onSubmit ~ status, loading, message:", message);
      if (status == "success") {
        history.push("/dashboard");
        toast.success("Login Successfully");
      } else if (message?.email) {
        toast.error(message?.email);
      } 
      else {
        toast.error(message);
      }
    } catch (err) {
      console.log(err.response.data.message, "err");
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <>
      <Container fluid className="common_bg login_bg position-relative">
        <div className="logo_sec">
          <img
            src={require("../assets/images/headerlogo.png")}
            className="img-fluid main_logo"
          />
        </div>

        <Row className="justify-content-center align-items-center row_card">
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="dashboard_box p-4">
              <div className="profile_holder">
                <p className="header_title_big">Login</p>
                <hr className="grey_hr" />
              </div>

              <div className="profile_holder">
                <Row>
                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Email Address</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type="text"
                          name="email"
                          onChange={handleChange}
                          placeholder="Enter Email Address"
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                      <span className="text-danger">{errors.email}</span>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Password</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <input
                          type={pwd ? "text" : "password"}
                          name="password"
                          onChange={handleChange}
                          placeholder="Enter Password"
                          className="rp_singleInput flex-grow-1"
                        />

                        <i
                          class={
                            pwd ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                          }
                          onClick={() => setPwd(!pwd)}
                        />
                      </div>
                      <span className="text-danger">{errors.password}</span>
                    </div>
                    <div className="mt-3 text-end">
                      <Link to="/forgotpassword" className="link_theme">
                        Forgot Password?
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <button
                    className="orange_small_primary"
                    onClick={() => {
                      onSubmit();
                    }}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
