import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { adminSupportReply } from "../actions/settings";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi";

const initialFormValue = {
  reply: "",
};

function ReplyTicket() {
  const location = useLocation();
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  // const navigate = useNavigate();
  const history = useHistory();

  console.log(
    JSON.parse(location.state.datas),
    "JSON.parse(location.state.datas"
  );

  useEffect(() => {
    setFormvalue(JSON.parse(location.state.datas));
  }, []);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.reply)) {
        error["reply"] = "Reply is required";
      } else {
        error["reply"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let isValidate = validation(formvalue);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let replyData = {
        _id: formvalue._id,
        reply: formvalue.reply,
        method : "post"
      };
      let encdata = encrypt(replyData);
      let { status, message, result } = await adminSupportReply(encdata);
      if (status == "success") {
        toast.success("Reply Send Successfully");
        history.push("/supportTicket");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Reply Ticket"} />
            <div className="mt-5 profile_holder ps-lg-3">
              <Row className="align-items-center">
                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Title</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Title"
                        name="subject"
                        value={formvalue.subject}
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Description</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <textarea
                        rows={3}
                        name="description"
                        value={formvalue.description}
                        placeholder="Description"
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              {formvalue.status == "open" ? (
                <Row className="align-items-center">
                  <Col lg={7} className="mb-3 mb-lg-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Reply</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <textarea
                          rows={3}
                          name="reply"
                          placeholder="Reply"
                          onChange={handleChange}
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                      <span className="text-danger">
                        {errors && errors.reply}
                      </span>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="align-items-center">
                  <Col lg={7} className="mb-3 mb-lg-4">
                    <div className="rp_singleinput_holder">
                      <p className="rp_label mb-2">Reply</p>
                      <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                        <textarea
                          rows={3}
                          name="reply"
                          value={formvalue.replyComment}
                          placeholder="Reply"
                          className="rp_singleInput flex-grow-1"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {formvalue.status == "open" ? (
                <Row className="align-items-center">
                  <Col lg={5} className="mb-4">
                    <div className="rp_singleinput_holder">
                      <button
                        className="orange_small_primary mt-4"
                        onClick={() => {
                          handleSubmit();
                        }}>
                        Reply
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReplyTicket;
