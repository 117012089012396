import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { changePassword } from "../actions/admin";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi";
function ResetPassword() {
  const [oldPw, setOldPw] = useState(false);
  const [newPw, setNewPw] = useState(false);
  const [confirmPw, setConfirmPw] = useState(false);

  const [formvalue, setFormvalue] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let error = { ...errors };
      error[`${name}`] = "";
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      setErrors(error);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      console.log(value.confirmPassword, "confirmPassword");
      if (isEmpty(value.oldPassword)) {
        error["oldPassword"] = "old password is required";
      } else {
        error["oldPassword"] = "";
      }
      if (isEmpty(value.newPassword)) {
        error["newPassword"] = "new password is required";
      } else {
        error["newPassword"] = "";
      }
      if (isEmpty(value.confirmPassword)) {
        error["confirmPassword"] = "confirm password is required";
      } else if (value.confirmPassword != value.newPassword) {
        error["confirmPassword"] =
          "Confrim password must match with new password";
      } else {
        error["confirmPassword"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      let userData = {
        oldPassword: formvalue.oldPassword,
        newPassword: formvalue.newPassword,
        confirmPassword: formvalue.confirmPassword,
        method : "post"
      };
      let isValidate = validation(userData);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      let data = encrypt(userData);
      let { status, message, result, error } = await changePassword(data);
      if (status == "success") {
        toast.success("Password Changed Successfully");
        // window.location.reload()
      }
      //   else if (error.oldPassword) {
      //     toast.error("Incorrect Old Password");
      //   } else if (error.newPassword) {
      //     toast.error("Password already exists");
      //   }
      else {
        toast.error(message);
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>

        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12} className="pe-3 ps-lg-4">
            <Header title={"Reset Password"} />
            <div className="mt-5 profile_holder">
              <Row>
                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Old Password</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={oldPw ? "text" : "password"}
                        placeholder="Enter Old Password"
                        className="rp_singleInput flex-grow-1"
                        name="oldPassword"
                        onChange={handleChange}
                      />

                      <i
                        class={
                          oldPw ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                        }
                        onClick={() => setOldPw(!oldPw)}
                      />
                    </div>
                    <span className="text-danger">{errors.oldPassword}</span>
                  </div>
                </Col>

                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">New Password</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={newPw ? "text" : "password"}
                        placeholder="Enter New Password"
                        className="rp_singleInput flex-grow-1"
                        name="newPassword"
                        onChange={handleChange}
                      />

                      <i
                        class={
                          newPw ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                        }
                        onClick={() => setNewPw(!newPw)}
                      />
                    </div>
                    <span className="text-danger">{errors.newPassword}</span>
                  </div>
                </Col>

                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Confirm password</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={confirmPw ? "text" : "password"}
                        placeholder="Enter Confirm Password"
                        className="rp_singleInput flex-grow-1"
                        name="confirmPassword"
                        onChange={handleChange}
                      />

                      <i
                        class={
                          confirmPw
                            ? "fa-solid fa-eye-slash"
                            : "fa-solid fa-eye"
                        }
                        onClick={() => setConfirmPw(!confirmPw)}
                      />
                    </div>
                    <span className="text-danger">
                      {errors.confirmPassword}
                    </span>
                  </div>
                </Col>
              </Row>

              <button
                className="orange_small_primary"
                onClick={() => {
                  handleSubmit();
                }}>
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResetPassword;
