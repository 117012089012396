import logo from "../src/assets/images/headerlogo.png";
import "./App.css";
import Dashboard from "./Screens/Dashboard";
import "../src/assets/css/Style.css";
import UserWallet from "./Screens/UserWallet";
import ResetPassword from "./Screens/ResetPassword";
import Userlist from "./Screens/Userlist";
import ReplyTicket from "./Screens/ReplyTicket";
import Profile from "./Screens/Profile";
import AddSettings from "./Screens/AddSettings";
import CmsList from "./Screens/CmsList";
import CMSedit from "./Screens/CmsEdit";
import SiteSettings from "./Screens/SiteSettings";

import Subadmin from "./Screens/Subadmin";
import AddSubadmin from "./Screens/AddSubadmin";
import EditSubadmin from "./Screens/EditSubadmin";

import Login from "./Screens/Login";
import Forgotpassword from "./Screens/Forgotpassword";
import Resetnewpassword from "./Screens/Resetnewpassword";
import SupportTicket from "./Screens/SupportTicket";
import Userhistory from "./Screens/Userhistory";
import ConditionRoute from "./Components/ConditionRoute";
import OtpVerification from "./Screens/OtpVerification";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import toast, { Toaster } from "react-hot-toast";
import Cms from "./Screens/Cms";
import Tip from "./Screens/Tip";
import Donation from "./Screens/Donation";

function App() {
  let records = store.getState();
  // return (
  //  <>
  //  <Routes>
  //   <Route path='/' element={<Login/>} />
  //   <Route path='/login' element={<Login/>} />
  //   <Route path='/forgotpassword' element={<Forgotpassword/>} />
  //   <Route path='/resetnewpassword' element={<Resetnewpassword/>} />

  //   <Route path='/dashboard' element={<Dashboard/>} />

  //   <Route path='/userWallet' element={<UserWallet/>} />
  //   <Route path='/supportTicket' element={<SupportTicket/>} />
  //   <Route path='/replyTicket' element={<ReplyTicket/>} />

  //   <Route path='/userlist' element={<Userlist/>} />
  //   <Route path='/userhistory' element={<Userhistory />} />

  //   <Route path='/profile' element={<Profile/>} />
  //   <Route path='/resetPassword' element={<ResetPassword/>} />
  //   <Route path='/addSettings' element={<AddSettings/>} />
  //   <Route path='/faqSettings' element={<FaqSettings/>} />

  //   <Route path='/siteSettings' element={<SiteSettings/>} />
  //   <Route path='/subAdmin' element={<Subadmin/>} />
  //   <Route path='/addsubAdmin' element={<AddSubadmin/>} />
  //   <Route path='/editsubAdmin' element={<EditSubadmin/>} />

  //  </Routes>
  //  </>
  // );

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          {/* <ToastContainer /> */}
          {/* <div className="App"> */}
          <div>
            <Toaster />
          </div>
          <Switch>
            <ConditionRoute
              exact
              path="/"
              component={Login}
              type={"public"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/forgotpassword"
              component={Forgotpassword}
              type={"public"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/resetnewpassword"
              component={Resetnewpassword}
              type={"public"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/otpVerification"
              component={OtpVerification}
              type={"public"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/dashboard"
              component={Dashboard}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/userWallet"
              component={UserWallet}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/supportTicket"
              component={SupportTicket}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/replyTicket"
              component={ReplyTicket}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/userlist"
              component={Userlist}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/userhistory"
              component={Userhistory}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/tip"
              component={Tip}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/donation"
              component={Donation}
              type={"private"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/profile"
              component={Profile}
              type={"private"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/resetPassword"
              component={ResetPassword}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/addSettings"
              component={AddSettings}
              type={"private"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/cmslist"
              component={CmsList}
              type={"private"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/cmsedit"
              component={CMSedit}
              type={"private"}
            ></ConditionRoute>

            <ConditionRoute
              exact
              path="/siteSettings"
              component={SiteSettings}
              type={"private"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/subAdmin"
              component={Subadmin}
              type={"private"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/addsubAdmin"
              component={AddSubadmin}
              type={"private"}
            ></ConditionRoute>
            <ConditionRoute
              exact
              path="/editsubAdmin"
              component={EditSubadmin}
              type={"private"}
            ></ConditionRoute>
          </Switch>
          {/* <Route exact path="*" component={NotFound} /> */}
          {/* </div> */}
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
