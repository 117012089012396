import { SET_CURRENT_USER } from "../actions/types";
import { isEmpty } from "../lib/isEmpty";
const initialState = {
  isAuthenticated: false,
  user: {},
  cms: {},
  updatecms: {},
  faq: {},
  replycontact: {},
  updatefaq: {},
  newuser: {},
  updatenewuser: {},
  forgot: {},
  template: {},
  updatetemplate: {},
  currencyadd: {},
  currencyupdate: {},
  loading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };

    default:
      return state;
  }
}
