import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import EditProfile from "../Modals/EditProfile";
import { getProfile } from "../actions/admin";
import { encrypt } from "../DecryptApi/decryptapi";

function Profile() {
  // modal
  const [show, setShow] = useState(false);

  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);
  const [profile, setProfile] = useState();

  const [editRecord, setEditRecord] = useState();

  const handleShow = (record) => {
    setEditRecord(record);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditRecord({});
  };

  const getAdminProfile = async () => {
    try {
      let reqData = {  method : "get" };
      let data = encrypt(reqData);
      let { status, message, result, count } = await getProfile(data);
      console.log(
        "🚀 ~ getAdminProfile ~ status, message, result, count:",
        status,
        message,
        result,
        count
      );
      if (status == "success") {
        setProfile(result);
      }
    } catch {}
  };

  useEffect(() => {
    getAdminProfile();
  }, []);

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12} className="pe-3 ps-4">
            <Header title={"My Profile"} />
            <div className="mt-5">
              <div className="text-end">
                <button
                  className="orange_small_primary"
                  onClick={() => handleShow(profile)}>
                  Edit Profile
                </button>
              </div>
              <Row className="profile_holder">
                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Name</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        className="rp_singleInput flex-grow-1"
                        value={profile?.name}
                        readOnly
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} md={8} sm={10} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Email</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={profile?.email}
                        readOnly
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* modals */}
      <EditProfile
        show={show}
        handleClose={handleClose}
        record={editRecord}
        getAdminProfile={getAdminProfile}
      />
      {/* end of modals */}
    </>
  );
}

export default Profile;
