import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { isEmpty } from "../lib/isEmpty";

// import options from "./data";

const MultiSelectAll = ({
  selectedOptions,
  setSelectedOptions,
  options,
  type,
}) => {
  //   const [selectedOptions, setSelectedOptions] = useState([]);
  // const { selectedOptions, setSelectedOptions, options } = props;

  useEffect(() => {
    if (type !== "edit" && !isEmpty(options)) {
      // setSelectedOptions([{ label: "All", value: "*" }, ...options]);
      setSelectedOptions([]);
    }
  }, [options]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value?.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedOptions([{ label: "All", value: "*" }, ...options]);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedOptions([]);
    } else if (event.action === "deselect-option") {
      setSelectedOptions(value.filter((o) => o.value !== "*"));
    } else if (value.length === options.length - 1) {
      setSelectedOptions(options);
    } else {
      setSelectedOptions(value);
    }
  }

  return (
    <ReactMultiSelectCheckboxes
      // {...props}
      className="react-select-container"
      classNamePrefix="react-select"
      options={
        isEmpty(options)
          ? [{ label: "All", value: "*" }]
          : [{ label: "All", value: "*" }, ...options]
      }
      placeholderButtonLabel="Permissions"
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions && selectedOptions}
      onChange={onChange}
      setState={selectedOptions && setSelectedOptions}
    />
  );
};

export default MultiSelectAll;
