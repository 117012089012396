import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LogoutModal from "../Modals/LogoutModal";
import nav from "../config/routes";
import { removeAuthToken } from "../lib/localStorage";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty } from "../lib/isEmpty";
function Sidebar() {
  const history = useHistory();

  let { restriction } = useSelector((state) => state.isRun);
  console.log("🚀 ~ Sidebar ~ restriction:", restriction);

  // modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="sidebar d-flex flex-column justify-content-start align-items-start ">
        <div className="w-100">
          <div className="sidebar_logo_holder px-4 py-3">
            <img
              src={require("../assets/images/headerlogo.png")}
              className="img-fluid main_logo"
            />
          </div>
          <ul className="pt-4">
            {nav && nav.length > 0
              ? nav.map((item) => {
                  // let Restriction = restriction
                  console.log(
                    item.path,
                    restriction,
                    restriction?.includes(item.path),
                    "restriction?.length"
                  );

                  if (
                    restriction?.length > 0 &&
                    restriction?.includes(item.path)
                  ) {
                    return (
                      //   <NavLink
                      //     to={item.path}
                      //     className="sidebar_links  rounded-end-5">
                      //     <li className="rounded-end-5 d-flex gap-3 p-2 ps-4 justify-content-start align-items-center mb-3">
                      //       <img
                      //         src={item.image}
                      //         className="img-fluid sidebar_linkImg"
                      //       />
                      //       <p className="sidebar_link_hint">{item.name}</p>
                      //     </li>
                      //   </NavLink>
                      <>
                        <li className="mb-3">
                          <NavLink
                            to={item.path}
                            className="sidebar_links flex-nowrap rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                            <img
                              src={item.image}
                              className="img-fluid sidebar_linkImg"
                            />
                            <p className="sidebar_link_hint">{item.name}</p>
                          </NavLink>
                        </li>
                      </>
                    );
                  } else if (isEmpty(restriction)) {
                    return (
                      <li className="mb-3">
                        <NavLink
                          to={item.path}
                          className="sidebar_links flex-nowrap rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                          <img
                            src={item.image}
                            className="img-fluid sidebar_linkImg"
                          />
                          <p className="sidebar_link_hint">{item.name}</p>
                        </NavLink>
                      </li>
                    );
                  }
                })
              : ""}

            {/* {restriction && restriction?.length > 0 ? (
              <>
                <li className="mb-3">
                  <NavLink
                    to="/resetPassword"
                    className="sidebar_links flex-nowrap rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img
                      src={require("../assets/images/userSetting.svg").default}
                      className="img-fluid sidebar_linkImg"
                    />
                    <p className="sidebar_link_hint">Admin Settings</p>
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )} */}
          </ul>
        </div>

        <div className="w-100">
          <li className="mb-3" onClick={handleShow}>
            <div className="sidebar_links rounded-end-5  d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
              <img
                src={require("../assets/images/logout.svg").default}
                className="img-fluid sidebar_linkImg"
              />
              <p className="sidebar_link_hint">Logout</p>
            </div>
          </li>
        </div>
      </div>

      {/* modals */}
      <LogoutModal show={show} handleClose={handleClose} />
      {/* end of modals */}
    </>
  );
}

export default Sidebar;
