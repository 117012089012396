// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios, { setAuthorization } from "../config/axios";
import Config from "../config/index";
import { API_URL } from "../config";

// import lib
import { setAuthToken } from "../lib/localStorage";
import { axiosFunc } from "../DecryptApi/decryptapi";

export const login = async (data, dispatch) => {
  try {
    console.log("login APIURL", API_URL, data);
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/login`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "login");
    console.log("respData_login", respData);
    if (respData.data.status == "TWO_FA") {
      return {
        status: "TWO_FA",
        loading: false,
        message: respData.data.message,
      };
    }

    setAuthorization(respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    console.log("🚀 ~ login ~ err:", err)
    return {
      status: "failed",
      loading: false,
      message: err?.response?.data?.errors,
      error: err?.response?.data?.errors,
    };
  }
};
export const adminForgotPassword = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/forgot-Password`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "adminForgotPassword");
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const verifyOtp = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/verify-Admin-Otp`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "verifyOtp");
    console.log('verifyOtp_respData', respData)
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const adminResetPassword = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/reset-password`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "adminResetPassword");
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const getAdminList = async (data) => {
  try {
    let reqData = {
      method: "get",
      url: `${API_URL}adminapi/admin/get-subAdmin`,
      params: { data },
    }
    let respData = await axiosFunc(reqData, "getAdminList");
    console.log("getAdminList_respDastas", respData)
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const creatAdmin = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/add-subAdmin`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "creatAdmin");
    console.log("respDatas_creatAdmin", respData);
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const EditAdmin = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/edit-subAdmin`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "EditAdmin");

    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const EditAdminStatus = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/edit-subAdmin-status`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "EditAdminStatus");

    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getGeoInfoData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${Config.getGeoInfo}`,
    });

    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/change-password`,
      data: { data },
    }
    let respData = await axiosFunc(reqData, "changePassword");
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const getProfile = async (data) => {
  try {
    let reqData = {
      method: "get",
      url: `${API_URL}adminapi/admin/get-profile`,
      params : { data },
    }
    let respData = await axiosFunc(reqData, "getProfile");
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const editProfile = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/edit-profile`,
      params: { data },
    }
    let respData = await axiosFunc(reqData, "editProfile");
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: err.response.data.status,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};



export const getUserList = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/get-users`,
      data : { data },
    }
    let respData = await axiosFunc(reqData, "getUserList");
    console.log("getUserList resp", respData)
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getUserHistory = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/user-History`,
      data : { data },
    }
    let respData = await axiosFunc(reqData, "getUserHistory");
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const EditUserStatus = async (data) => {
  try {

    let reqData = {
      method: "post",
      url: `${API_URL}adminapi/admin/edit-user-status`,
      data : { data },
    }


    let respData = await axiosFunc(reqData, "EditUserStatus");

    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.errors,
      error: err.response.data.errors,
    };
  }
};

export const DeleteSingleUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `adminapi/admin/delete-User`,
      data,
    });

    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const DashData = async (data) => {
  try {
    let reqData = {
      method: "get",
      url: `${API_URL}adminapi/admin/dashdata`,
      params: { data },
    }
    let respData = await axiosFunc(reqData, "DashData");
    return {
      status: respData.data.status ? "success" : "failed",
      loading: false,
      message: respData.data.message
        ? respData.data.message
        : respData.data.errors,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
