import React, { useRef, useState, Fragment, useEffect } from "react";
import Header from "../Components/Header";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Lottie from "lottie-react";
import bitcoin from "../assets/lotties/ye.json";
import thunder from "../assets/lotties/u.json";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { DashData } from "../actions/admin";
import { encrypt } from "../DecryptApi/decryptapi";

function Dashboard() {
  const switcher = useSelector((state) => state.switcher);
  let { restriction } = useSelector((state) => state.isRun);
  console.log(restriction, "restrictionrestriction");
  const dispatch = useDispatch();

  const coinRef = useRef();
  const ref = useRef();
  const [chartPeriod, setChartPeriod] = useState("1h");

  const [closer, setCloser] = useState();
  const [userCount, setUserCount] = useState("");

  useEffect(() => {
    getDashData();
  }, []);

  const getDashData = async () => {
    try {
      let reqData = { method : "get" };
      let data = encrypt(reqData);
      let { status, message, result } = await DashData(data);;
      console.log("result getDashData", result)
      setUserCount(result.users);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Dashboard"} switcher={switcher} />

            <div className="common_page_scroller mt-4 mt-sm-5 pe-2">
              <Row className="">
                <Col lg={8} md={8} className="mb-4 mb-lg-0">
                  <Row
                    className={`${
                      switcher
                        ? "flex-column-reverse flex-sm-row-reverse flex-md-column-reverse flex-lg-row-reverse"
                        : ""
                    }`}>
                    {restriction?.length > 0 ? (
                      restriction?.includes("/userlist") ? (
                        <Col lg={6} md={12} sm={6} className="mb-4 mb-lg-0">
                          <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                            <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                              <div className="dash_lottie_holder position-relative">
                                <Lottie
                                  animationData={bitcoin}
                                  className="dash_lottie"
                                  loop={true}
                                />
                                <img
                                  src={require("../assets/images/user.png")}
                                  className="img-fluid dash_coinImg"
                                />
                              </div>
                            </div>
                            <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                              <div className="">
                                <p className="dash_graymed_text">Users</p>
                                <p className="dash_valuewhite_text mt-1">
                                  {userCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )
                    ) : (
                      <Col lg={6} md={12} sm={6} className="mb-4 mb-lg-0">
                        <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                          <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                            <div className="dash_lottie_holder position-relative">
                              <Lottie
                                animationData={bitcoin}
                                className="dash_lottie"
                                loop={true}
                              />
                              <img
                                src={require("../assets/images/user.png")}
                                className="img-fluid dash_coinImg"
                              />
                            </div>
                          </div>
                          <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                            <div className="">
                              <p className="dash_graymed_text">Users</p>
                              <p className="dash_valuewhite_text mt-1">
                                {" "}
                                {userCount}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    <Col lg={6} md={12} sm={6} className="mb-4 mb-lg-0">
                      <div className="dashboard_box p-3 rounded-3 d-flex flex-column gap-4">
                        <div className="dashbox_topflex d-flex justify-content-between align-items-center">
                          <div className="dash_lottie_holder position-relative">
                            <Lottie
                              animationData={thunder}
                              className="dash_lottie"
                              loop={true}
                            />
                            <img
                              src={require("../assets/images/fund.png")}
                              className="img-fluid dash_coinImg"
                            />
                          </div>
                        </div>
                        <div className="dashbox_bottomflex d-flex justify-content-between align-items-end">
                          <div className="">
                            <p className="dash_graymed_text">Token Funding</p>
                            <p className="dash_valuewhite_text mt-1">$0</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
