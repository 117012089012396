import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import userhistory from "../Components/data/userhistory.js";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { getUserHistory } from "../actions/admin";
import toast from "react-hot-toast";
import { MdOutlineArrowOutward } from "react-icons/md";
import { ImArrowUpRight } from "react-icons/im";
import { ImArrowUpRight2 } from "react-icons/im";
import { FaCopy } from "react-icons/fa";
import { getUserTipdetails } from "../actions/settings.js";
import CopyToClipboard from "react-copy-to-clipboard";
import { encrypt } from "../DecryptApi/decryptapi.js";


function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i <= pages; i++) {
    results.push(i);
  }

  return results;
}

const copyCode = (a, b) => {
  toast.success("Wallet address Copied")
}

const columns = [
  {
    name: "S.no",
    selector: (row,index) => index + 1,
    // sortable: true,
  },
  {
    name: "Sender Username",
    selector: (row) => row.senderusername,
    // sortable: true,
  },
  {
    name: "Receiver Username",
    selector: (row) => row.receivername,
    // sortable: true,
  },
  {
    name: "Sender Wallet Address",
    selector: (row) => (
      <div className="d-flex align-items-center gap-1">
        <span className="wallet_address">{row.senderwalletaddress}</span>
        <CopyToClipboard text={`${row.senderwalletaddress && row.senderwalletaddress}`} onCopy={() => copyCode('invite link', `${row.senderwalletaddress && row.senderwalletaddress}`)}>
        <span className="copy_addressIcon">
          <FaCopy />
        </span>
        </CopyToClipboard>
      </div>
    ),
    // sortable: true,
  },
  {
    name: "Receiver Wallet Address",
    selector: (row) => (
      <div className="d-flex align-items-center gap-1">
        <span className="wallet_address">{row.receiverwalletaddress}</span>
        <CopyToClipboard text={`${row.receiverwalletaddress && row.receiverwalletaddress}`} onCopy={() => copyCode('invite link', `${row.receiverwalletaddress && row.receiverwalletaddress}`)}>
        <span className="copy_addressIcon">
          <FaCopy />
        </span>
        </CopyToClipboard>
       
      </div>
    ),
    // sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    // sortable: true,
  },
  {
    name: "Status",
    selector: (row) => "Success",
    // sortable: true,
  },

  // {
  //   name: "View on Explorer",
  //   button: true,
  //   cell: (row) => (
  //     <div className="tips_hashBtn">
  //       {/* <a href="#" target="_blank"> //row.hash*/} 
  //         <button type="button" className="orange_small_primary">
  //           <ImArrowUpRight2 />
  //         </button>
  //       {/* </a> */}
  //     </div>
  //   ),
  // },
];

const data = [
  {
    id: 1,
    senderId: "Abc",
    receiverId: "Xyz",
    senderWA: "ECUFbzphqqTtDv6Dr2hNQs8EvH",
    receiverWA: "2hNQs8EvHkwm1Tp8wtGzRVyyXnE",
    amount: "10.03",
  },
  //   {
  //     id: 2,
  //     title: "Ghostbusters",
  //     year: "1984",
  //   },
];

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {

  
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;




  return (
    <nav className="d-flex justify-content-end mt-3">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page"
          >
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}
              >
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

function Tip() {
  const [userHistory, setUserHistory] = useState();
  const [Records,setRecords] = useState([])


  useEffect(() =>{

    Tipdata()
  },[])


const Tipdata = async() =>{
let reqData = { method : 'get' };
let data = encrypt(reqData);
  var tipdata = await getUserTipdetails(data);
  console.log("tipdatasgal", tipdata)
  setRecords(tipdata?.data?.result);
}



  const getUserAllHistory = async () => {
    try {
      let reqData = { method: "post" };
      let data = encrypt(reqData)
      let { status, message, result, count } = await getUserHistory(data);
      if (status == "success") {
        setUserHistory(result);
      }
    } catch {}
  };

  useEffect(() => {
    getUserAllHistory();
  },[]);

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Tip"} />

            <div className="common_page_scroller mt-5 pe-2">
              <div className="tips_table rounded-3 mt-4 p-3">
                <DataTable
                  columns={columns}
                  data={Records}
                  defaultSortFieldID={1}
                  pagination
                  paginationComponent={BootyPagination}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tip;
