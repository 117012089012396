import React from "react";
import { Modal } from "react-bootstrap";
import { EditUserStatus } from "../actions/admin";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi";

function DisableModal({
  show,
  handleClose,
  blocklist,
  record,
  getUserAllList,
}) {
  console.log(blocklist, record, "blocklist");
  const editUserStatus = async () => {
    try {
      let reqData = { method: "post", status: blocklist == "Disable" ? false : true, _id: record };
      let data = encrypt(reqData);
      let { status, message, result } = await EditUserStatus(data);
      console.log(status, message, result, "status, message, result");
      if (status == "success") {
        toast.success("User Status Changed");
        getUserAllList();
      } else {
        toast.error(message);
        getUserAllList();
      }
    } catch (err) {
      console.log("deleteUser_err", err);
    }
  };

  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">{blocklist} User</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={handleClose}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-3 pb-2">
            <p className="dash_graymed_text">
              Are you sure want to {blocklist} this user?
            </p>
            <button
              className="orange_small_primary mt-5"
              onClick={() => {
                editUserStatus();
                handleClose();
              }}>
              Confirm
            </button>
            <button
              className="orange_small_secondary mt-5 ms-3"
              onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DisableModal;
