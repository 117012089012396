let key = {};
let env = "";
// export const API_URL = "http://localhost:8080/";

export const ADMIN_URL = "https://alienium-admin.maticz.in";
export const API_URL = "https://backend-alienium.maticz.in/";

//common
export const KEY = "ALIENIUM$!@2";
export const getGeoInfo = "https://ipapi.co/json/"
// export const PORT = 8080;
// if (process.env.NODE_ENV === "production") {
if (env === "production") {
  console.log("Set Production Config");
  const API_URL = "";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    API_URL: API_URL,
    FRONT_URL: "",
    ADMIN_URL: "",
    getGeoInfo: "https://ipapi.co/json/",
    KEY : "ALIENIUM$!@2"
  };
} else if (env === "demo") {
  console.log("Set Demo Config");
  const API_URL = "";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    API_URL: API_URL,
    FRONT_URL: "",
    ADMIN_URL: "",
    getGeoInfo: "https://ipapi.co/json/",
    KEY : "ALIENIUM$!@2"
  };
} else {
  console.log("Set Development Config");
  // const API_URL = "https://backend-alienium.maticz.in";
  const API_URL = "http://localhost:8080";
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    Recaptchakey: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    // API_URL: `${API_URL}:8080`,
    API_URL: `${API_URL}`,
    FRONT_URL: "http://localhost",
    ADMIN_URL: "https://alienium-admin.maticz.in",

    ADMIN_URL: "https://alienium-admin.maticz.in",
    
    getGeoInfo: "https://ipapi.co/json/",
    KEY : "ALIENIUM$!@2"
  };
}

export default key;
