import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Select from "react-select";
import nav from "../config/routes";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import { creatAdmin } from "../actions/admin";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import MultiSelectAll from "../Components/MultiSelectAll";
import { encrypt } from "../DecryptApi/decryptapi";

const initialFormValue = {
  name: "",
  email: "",
  password: "",
  role: "subadmin",
  restriction: [],
};

function AddSubadmin() {
  const [pwd, setPwd] = useState(false);

  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedOption1, setSelectedOption1] = useState();

  var email_regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  var pattern = new RegExp(email_regex);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      // if (name == "email") {
      //   var pattern = new RegExp(email_regex);
      //   if (!pattern.test(value)) {
      //     return;
      //   }
      // }
      let Formvalue = { ...formvalue, [name]: value };
      let error = { ...errors };
      error[`${name}`] = "";
      setFormvalue(Formvalue);
      setErrors(error);
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    console.log("🚀 ~ validation ~ value:", value)
    try {
      let error = { ...errors };
      var pattern = new RegExp(email_regex);
      if (isEmpty(value.name)) {
        error["name"] = "Name is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.email)) {
        error["email"] = "Email is required";
      } else if (isEmpty(value.email?.match(pattern))) {
        error["email"] = "Enter Valid Address";
      } else {
        error["email"] = "";
      }
      if (isEmpty(value.password)) {
        error["password"] = "Password is required";
      } else {
        error["password"] = "";
      }
      console.log(
        isEmpty(value.restriction),
        value.restriction,
        "isEmpty(value.restriction)"
      );
      if (isEmpty(selectedOptions)) {
        error["restriction"] = "Permissions is required";
      } else {
        error["restriction"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };
  console.log("selectedOptions_data", selectedOptions)
  const handleSubmit = async () => {
    try {
      const { email, name, password, role } = formvalue;

      setSelectedOption1(
        selectedOptions.map((val) => {
          return val.value;
        })
      );

      let oj = {
        email: formvalue.email,
        name: formvalue.name,
        password: formvalue.password,
        role: formvalue.role,
        restriction: selectedOption1,
        method: "post"
      };
      let isValidate = validation(oj);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      }
      console.log("selectedOptions_data", selectedOptions);
      let data = encrypt(oj);
      let { status, message, result } = await creatAdmin(data);
      console.log(
        "🚀 ~ handleSubmit ~ status, message, result:",
        status,
        message,
        result
      );
      if (status == "success") {
        toast.success("Sub Admin Added Successfully");
        history.push("/subAdmin");
      } else {
        toast.error(message);
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  const [options, setOptions] = useState();

  const setNavOptions = (async) => {
    console.log(nav, "NAV NAV");
    let data =
      nav &&
      nav.length > 0 &&
      nav.map((item, i) => {
        return {
          label: item.name,
          value: item.path,
        };
      });

    let selectedOption = [];
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].value == "/subAdmin" ||
        data[i].value == "/profile" ||
        data[i].value == "/resetPassword"
      ) {
        continue;
      } else {
        selectedOption.push({
          label: data[i].label,
          value: data[i].value,
        });
      }
    }
    setOptions(selectedOption);
  };
  useEffect(() => {
    setNavOptions();
  }, [nav]);

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#74fdfc"
          : isSelected
          ? "#74fdfc"
          : isFocused
          ? "#74fdfc"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: "43px",
      padding: "0px 10px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      // height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    // indicatorsContainer: (provided, state) => ({
    //   ...provided,
    //   // height: "40px",
    //   position: "absolute",
    //   right: 0,
    //   top: 0,
    //   color: "red",
    //   padding: "0px",
    // }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#ffffff",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,
      // width: "100%",

      borderRadius: 5,
      background: "transparent",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#fff",
      };
    },
  };

  const handleChange1 = (e) => {
    console.log(e, "selectedOptionselectedOption");

    setSelectedOption1(
      e.map((val) => {
        return val.value;
      })
    );
  };

  const MultiOptions = [
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
  ];

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Add Subadmin"} />
            <div className="mt-5 profile_holder ps-lg-3 pb-5">
              <Row>
                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Name</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </Col>

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Email</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                    <span className="text-danger">{errors.email}</span>
                  </div>
                </Col>

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Password</p>

                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type={pwd ? "text" : "password"}
                        placeholder="Enter Password"
                        className="rp_singleInput flex-grow-1"
                        name="password"
                        onChange={handleChange}
                      />

                      <i
                        class={
                          pwd ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                        }
                        onClick={() => setPwd(!pwd)}
                      />
                    </div>
                    <span className="text-danger">{errors.password}</span>
                  </div>
                </Col>

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Role</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Role"
                        name="role"
                        value="subadmin"
                        onChange={handleChange}
                        className="rp_singleInput flex-grow-1"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Permissions</p>
                    {/* <div className="rp_input_holder rounded-2 py-1 px-0">
                      <Select
                        onChange={(e) => handleChange1(e)}
                        options={options}
                        defaultValue=""
                        styles={stylesgraybg}
                        isSearchable={false}
                        name="restriction"
                        isMulti
                        classNamePrefix="customselect"
                        value={
                          selectedOption1 && selectedOption1?.length > 0
                            ? options?.filter((el) => {
                                if (selectedOption1.includes(el.value)) {
                                  return el;
                                }
                              })
                            : []
                        }
                      />
                    </div> */}

                    <div className="multi_select_all">
                      <MultiSelectAll
                        options={options}
                        setSelectedOptions={(data) => {
                          setSelectedOptions(data);
                        }}
                        selectedOptions={selectedOptions}
                      />
                    </div>

                    <span className="text-danger">{errors.restriction}</span>
                  </div>
                </Col>
              </Row>
              <button
                className="orange_small_primary"
                onClick={() => {
                  handleSubmit();
                  // handleClose();
                }}>
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddSubadmin;
