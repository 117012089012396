import React, { useState } from 'react'
import { Col, Row, Dropdown, Container, Offcanvas } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux' 
import LogoutModal from '../Modals/LogoutModal';
import WalletconnectModal from "../Modals/WalletconnectModal";
import NetworkModal from "../Modals/NetworkModal";

function Header({title}) {

    //modal
    const [showLogout, setShowLogout] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const [showNetwork, setShowNetwork] = useState(false);

    


   const handleCloseLogout = () => setShowLogout(false);
   const handleShowLogout = () => setShowLogout(true);

   const handleCloseWallet = () => setShowWallet(false);
   const handleShowWallet = () => setShowWallet(true);

   const handleCloseNetwork = () => setShowNetwork(false);
   const handleShowNetwork = () => setShowNetwork(true);

    const dispatch = useDispatch(); 
    const switcher = useSelector((state) => state.switcher);
    const [mobSearch,setMobSearch] = useState(false)

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setSwitcher = (val) => {
    dispatch({
        type:"switching",
        value:{switcher:val}
    })
  }

  return (
    <>
    <div className='header mt-1 mt-sm-3 py-2 px-2 position-relative'>
        {mobSearch && 
    <div className='mob_searchbar_holder p-3 d-flex justify-content-start align-items-center gap-3'>
                            <input type='text' placeholder='Search' className='mob_searchbar_inp flex-grow-1' />
                            <i class="fa-solid fa-xmark" onClick={() => setMobSearch(false)}  />
                    </div>}
    <Row className='align-items-center'>
        <Col xl={6} lg={4} md={4} xs={8} className='d-flex gap-3'>
            <p className='header_title d-block d-xl-none' onClick={handleShow}><i class="fa-solid fa-bars" /></p>
            <p className='header_title'>{title}</p>
        </Col>
      
    </Row>
    </div>

    {/* offcanva content */}
    <Offcanvas show={show} onHide={handleClose} backdrop="static" className='header_canvas'>
        <Offcanvas.Body>
            <div className='header_canva_header pb-2 d-flex justify-content-between align-items-center'>
                <img src={require('../assets/images/headerlogo.png')} className='img-fluid img_mobile_header' />
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>
            <ul className="pt-4">
            <li className="rounded-end-5 mb-3">
                <NavLink to='/' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/grid.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Dashboard</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/userlist' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/exchange.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">User List</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/userhistory' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/exchange.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">User History</p>
                </NavLink>
            </li>


            <li className="rounded-end-5 mb-3">
                <NavLink to='/faqSettings' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/create.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">FAQ Settings</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/siteSettings' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/editer.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Site Settings</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/resetPassword' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/userSetting.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">User Settings</p>
                </NavLink>
            </li>

            <li className="rounded-end-5 mb-3">
                <NavLink to='/subAdmin' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/subadmin.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Sub Admin</p>
                </NavLink>
            </li>


            <li className="rounded-end-5 mb-3">
                <NavLink to='/supportTicket' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/ticket.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Support Ticket</p>
                </NavLink>
            </li>
           

            <li className="rounded-end-5 mb-3">
                <NavLink to='/userWallet' className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                    <img src={require('../assets/images/user.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">My Profile</p>
                </NavLink>
            </li>

          
          
            <li className="rounded-end-5" onClick={handleShowLogout}>
                <NavLink to="/" className="sidebar_links d-flex gap-3 justify-content-start align-items-center p-2 ps-4">
                <img src={require('../assets/images/logout.svg').default} className="img-fluid sidebar_linkImg" />
                    <p className="sidebar_link_hint">Logout</p>
                </NavLink>
            </li>
            
        </ul>

      

        
        </Offcanvas.Body>
      </Offcanvas>
    {/* end of offcanva content */}

    {/* modals */}
            <LogoutModal show={showLogout} handleClose={handleCloseLogout} />
            <WalletconnectModal show={showWallet} handleClose={handleCloseWallet} />
            <NetworkModal show={showNetwork} handleClose={handleCloseNetwork} />


            
    {/* end of modals */}
    </>
  )
}

export default Header