import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { editProfile } from "../actions/admin";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi";

const initialFormValue = {
  name: "",
  email: "",
};

function EditProfile({ show, handleClose, record, getAdminProfile }) {
  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (!isEmpty(record)) {
      setFormvalue(record);
    }
  }, [record]);

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      let Formvalue = { ...formvalue, [name]: value };
      setFormvalue(Formvalue);
      // setErrors({});
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const validation = (value) => {
    try {
      let error = { ...errors };
      if (isEmpty(value.name)) {
        error["name"] = "Name is required";
      } else {
        error["name"] = "";
      }
      if (isEmpty(value.email)) {
        error["email"] = "Email is required";
      } else {
        error["email"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleSubmit = async () => {
    try {
      const { email, name } = formvalue;
      let oj = {
        email: formvalue.email,
        name: formvalue.name,
        method : "post"
      };
      let isValidate = validation(oj);
      setErrors(isValidate);
      if (!ObjectIsempty(isValidate)) {
        return true;
      };
      let data = encrypt(oj);
      let { status, message, result } = await editProfile(data);
      if (status == "success") {
        toast.success("Profile Updated Successfully");
        handleClose();
        getAdminProfile();
        setErrors({});
      } else {
        toast.error(message);
        handleClose();
        getAdminProfile();
        setErrors({});
      }
      handleClose();
      getAdminProfile();
      setErrors({});
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };
  const HandleClose = () => {
    handleClose();
    setErrors({});
  };
  return (
    <>
      <Modal
        centered
        className="cmn_modal"
        show={show}
        onHide={() => HandleClose()}
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="cmn_modal_header d-flex justify-content-between align-items-center">
            <p className="cmn_modal_title">Edit Profile</p>
            <button
              className="cmn_modal_closer rounded-5"
              onClick={() => HandleClose()}>
              <i class="fa-solid fa-xmark" />
            </button>
          </div>

          <div className="mt-4 pb-2">
            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Name</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  readOnly
                  value={formvalue?.name}
                  onChange={handleChange}
                  className="rp_singleInput flex-grow-1"
                />
              </div>
              <span className="text-danger">{errors.name}</span>
            </div>

            <div className="rp_singleinput_holder mb-3">
              <p className="rp_label mb-2">Email</p>
              <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formvalue?.email}
                  onChange={handleChange}
                  className="rp_singleInput flex-grow-1"
                />
              </div>
              <span className="text-danger">{errors.email}</span>
            </div>

            <button
              className="orange_small_primary"
              onClick={() => handleSubmit()}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditProfile;
