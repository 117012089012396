import crypto from "crypto-js";
import axios from "axios";
import isEmpty from "is-empty";
import key from "../config";


export const axiosFunc = async (data, funcname) => {
    console.log("admin KEYKEY", key.KEY, data)
    // console.log("reqData_axiosFunc", funcname,data);
    try {
        let respdata = await axios(data);
        console.log("Resp?.data reqData_axiosFunc", funcname, data, respdata?.data, decrypt(respdata?.data), isEmpty(decrypt(respdata?.data)));
        if (respdata?.data) {
            respdata.data = respdata?.data ? isEmpty(decrypt(respdata?.data)) ? respdata?.data : decrypt(respdata?.data) : respdata?.data;
        }
        console.log("admin axiosFunc_data", funcname, respdata?.data)
        return respdata?.data;
    } catch (e) {
        console.log("axiosFunc_Err", e);
        if (e?.response?.status == 400) {
            if (Object.values(e?.response?.data?.errors)?.length > 0) {
                return e?.response?.data?.errors;
            }
        }

    }
};



export const encrypt = (data) => {
    try {
        if (typeof (data) == 'string') {
            return crypto.AES.encrypt(data, key.KEY).toString();
        }
        else {
            return crypto.AES.encrypt(JSON.stringify(data), key.KEY).toString();
        }
    }
    catch (error) {
        console.log(" encrypt_errr", error)
        return false;
    }

}

export const decrypt = (data) => {
    try {
        var bytes = crypto.AES.decrypt(data, key.KEY);
        var decryptedData;
        try {
            decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
        }
        catch (err) {
            try {
                decryptedData = bytes.toString(crypto.enc.Utf8);
            }
            catch (dcpterr) {
                return false;
            }
        }
        return decryptedData;
    }
    catch (error) {
        console.log("decrypt_err", error)
        return false;
    }

}


export const AppenData = (data) => {

    var formdata = new FormData()
    var SendDta = Object.entries(data).map((item) => {
        console.log("admin AppenData_item", item);
        if (Array.isArray(item[1])) {
            var come = item[1].map(data => {
                if (data?.type && data?.size) {
                    formdata.append(item[0], data)
                }
                else {
                    formdata.append(item[0], encrypt(data))
                }
                return formdata
            })
  
            return come
        }
        else {
            if (item[1]?.type && item[1]?.size) {
                console.log("22 appiitem",item[0], item[1]);
                formdata.append(item[0], item[1])
            }
            else {
                console.log("appiitem",item[0], item[1]);
                if(item[1] != "") {
                    formdata.append(item[0], encrypt(item[1]))
                }
            }
            return formdata
        }
    })
    return formdata
  }