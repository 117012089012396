// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios, { setAuthorization } from "../config/axios";
import Config, { API_URL } from "../config/index";

// import lib
import { setAuthToken } from "../lib/localStorage";
import { axiosFunc } from "../DecryptApi/decryptapi";

export const addSiteSettigs = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}sitesettingapi/addSiteSetting`,
      data : { data },
    }
    let respData = await axiosFunc(reqData, "addSiteSettigs");
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const getSiteSettigs = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}sitesettingapi/getSiteSetting`,
      data : { data },
    }
    let respData = await axiosFunc(reqData, "getSiteSettigs")
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const updateSiteSettigs = async (formData) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}sitesettingapi/updateSiteSetting`,
      data : formData
    }
    let respData = await axiosFunc(reqData, "updateSiteSettigs");
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getAllTikcetList = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}sitesettingapi/support/ticketList`,
      data : { data }
    }
    let respData = await axiosFunc(reqData, "getAllTikcetList");
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const adminSupportReply = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}sitesettingapi/support/admin-Reply`,
      data : { data }
    }
    let respData = await axiosFunc(reqData, "adminSupportReply");
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getAllCMS = async (data) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}sitesettingapi/get-cms-list`,
      data : { data },
    }
    let respData = await axiosFunc(reqData, "getAllCMS");
    console.log("getAllCMS respDasta", respData);
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const updateCMSData = async (formdata) => {
  try {
    let reqData = {
      method: "post",
      url: `${API_URL}sitesettingapi/update-cms`,
      data :  formdata ,
    }
    let respData = await axiosFunc(reqData, "updateCMSData")
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};



export const getUserTipdetails = async (data) => {

  try {
    let reqData = {
      method: "get",
      url: `${API_URL}tipapi/get/tip/data`,
      params: {data},
    }
    let result = await axiosFunc(reqData, "getUserTipdetails");
    console.log("result getUserTipdetails", result)
    return result;
  } catch (err) {
      console.log('errrrrrrrrr', err)
  }
}




export const getUserDonariondetails = async (data) => {

  try {
    let reqData = {
      method: "get",
      url: `${API_URL}tipapi/get/donation/data`,
      params: { data },
    }
    let result = await axiosFunc(reqData, "getUserDonariondetails")
    return result;


  } catch (err) {
      console.log('errrrrrrrrr', err)
  }
}
