import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'

function AddSettings() {
  return (
    <>
    <Container fluid className='common_bg position-relative'>
        <div className='liner'></div>
        <Row>
            <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                <Sidebar/>
            </Col>
            <Col xl={10} lg={12}>
    <Header title={'Add Settings'}/> 
    <div className='mt-5 profile_holder ps-lg-3'>
                <Row>
                    <Col lg={7} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Settings Name</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Settings Name' className='rp_singleInput flex-grow-1' />

                    </div>
                </div>
                    </Col>

                    <Col lg={7} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Settings Value</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Settings Value' className='rp_singleInput flex-grow-1' />

                    </div>
                </div>
                    </Col>
                    </Row>
                    <button className='orange_small_primary'>Submit</button>
                    </div>
    </Col>
    </Row>
        </Container>
        </>
  )
}

export default AddSettings