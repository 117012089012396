import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { API_URL } from "../config";
import { update, updateCMSData } from "../actions/settings";
import { isEmpty, ObjectIsempty } from "../lib/isEmpty";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AppenData, encrypt } from "../DecryptApi/decryptapi";

const initialFormValue = {
  heading: "",
  title1: "",
  title2: "",
  content1: "",
  content2: "",
  image: [],
  cmscard: [],
};

function CMSedit() {
  const [count, setCount] = useState(1);

  const [formvalue, setFormvalue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let FormData = JSON.parse(location.state.datas);
    setFormvalue(FormData);
    setCount(FormData?.cmscard?.length);
  }, [location]);

  const validation = (value) => {
    try {
      let error = errors;
      if (isEmpty(value.heading)) {
        error["heading"] = "Field is required";
      } else {
        error["heading"] = "";
      }
      if (isEmpty(value.title)) {
        error["title"] = "Field is required";
      } else {
        error["title"] = "";
      }
      if (isEmpty(value.content)) {
        error["content"] = "Field is required";
      } else {
        error["content"] = "";
      }

      if (isEmpty(value.cmscard)) {
        error["cmscard"] = "Field is required";
      } else {
        error["cmscard"] = "";
      }

      if (typeof value.image != "object" && isEmpty(value.image)) {
        error["image"] = "Field is required";
      } else {
        error["image"] = "";
      }
      return error;
    } catch (err) {
      console.log(err, "validation__err");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    var imgurl = [];
    let Formvalue;
    if (name == "image") {
      for (let i = 0; i < event.target.files.length; i++) {
        imgurl.push(event.target.files[i]);
      }
      Formvalue = { ...formvalue, [name]: imgurl };
    } else {
      Formvalue = { ...formvalue, [name]: value };
    }
    setFormvalue(Formvalue);
    // setErrors(validation(Formvalue));
  };

  const handleCmscard = async (e, index) => {
    try {
      const { name, value } = e.target;
      let arr = [...formvalue.cmscard];
      let val = arr[index] ? arr[index] : {};
      if (name == "cardImage") {
        val[`${name}`] = e.target.files[0];
      } else {
        val[`${name}`] = value;
      }
      arr[index] = val;
      console.log(arr, "handleCmscard");
      let Formvalue = { ...formvalue, ["cmscard"]: arr };
      setFormvalue(Formvalue);
      // setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleHighlights");
    }
  };

  const handleRemove = async (e, index) => {
    try {
      console.log(index, "INDEX");
      let arr = [...formvalue.cmscard];
      console.log(arr.length, arr.length - 1, "INDEX");
      arr.splice(arr.length - 1, 1);
      console.log(arr, "INDEX");
      let Formvalue = { ...formvalue, ["cmscard"]: arr };
      setFormvalue(Formvalue);
      // setErrors(validation(Formvalue));
    } catch (err) {
      console.log(err, "handleHighlights");
    }
  };

  const handleSubmit = async (type) => {
    console.log(type, "TARGET");

    try {
      // let isValidate = validation(formvalue);
      // console.log("🚀 ~ handleSubmit ~ isValidate:", isValidate);
      // setErrors(isValidate);
      // if (!ObjectIsempty(isValidate)) {
      //   return true;
      // }
      if (type == "cancel") {
        history.push("/cmslist");
      } else {
        let reqData = {
          _id : formvalue._id,
          heading : formvalue.heading == undefined ? "" : formvalue.heading,
          title1 : formvalue.title1 == undefined ? "" : formvalue.title1,
          title2 : formvalue.title2 == undefined ? "" : formvalue.title2,
          content1: formvalue.content1 == undefined ? "" : formvalue.content1,
          content2: formvalue.content2 == undefined ? "" : formvalue.content2,

        }
        let formData = AppenData(reqData);
        for (let i = 0; i < formvalue.cmscard.length; i++) {
          if (formvalue.cmscard[i].cardImage != undefined) {
            formData.append("images", formvalue.cmscard[i].cardImage);
          }
        }

        for (let j = 0; j < formvalue.image.length; j++) {
          console.log(formvalue.image, "formvalue.image");
          formData.append("image", formvalue.image[j]);
        }

        if (formvalue.cmscard.length > 0) {
          formData.append("cmscard", JSON.stringify(formvalue.cmscard));
        } else {
          formData.append("cmscard", formvalue.cmscard);
        }
        formData.append("method", "post");
        let { status, message, result } = await updateCMSData(formData);
        if (status == "success") {
          toast.success("CMS Settings Updated");
          history.push("/cmslist");
        }
      }
    } catch (err) {
      console.log(err, "handleSubmit__err");
    }
  };

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"CMS Settings"} />
            <div className="mt-5 profile_holder ps-lg-3">
              <Row className="align-items-center">
                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Heading</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <input
                        type="text"
                        placeholder="Heading"
                        className="rp_singleInput flex-grow-1"
                        name="heading"
                        value={formvalue?.heading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Title 1</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <textarea
                        rows={3}
                        type="text"
                        placeholder="title1"
                        className="rp_singleInput flex-grow-1"
                        name="title1"
                        value={formvalue?.title1}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Title 2</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <textarea
                        rows={3}
                        type="text"
                        placeholder="title2"
                        className="rp_singleInput flex-grow-1"
                        name="title2"
                        value={formvalue?.title2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Content 1</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <textarea
                        rows={3}
                        type="text"
                        placeholder="Content1"
                        className="rp_singleInput flex-grow-1"
                        name="content1"
                        value={formvalue?.content1}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Content 2</p>
                    <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                      <textarea
                        rows={3}
                        type="text"
                        placeholder="Content2"
                        className="rp_singleInput flex-grow-1"
                        name="content2"
                        value={formvalue?.content2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={7} className="mb-3 mb-lg-4">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2">Images</p>
                    <label
                      for="file-upload"
                      className="custom-file-upload mt-3">
                      Custom Upload
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      name="image"
                      className="hide_input"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      multiple
                    />

                    <Row className="mt-3">
                      <Col lg={3}>
                        {formvalue?.image.length > 0 &&
                          formvalue?.image.map((i, index) => {
                            console.log(i, "formvalue?.image");
                            return (
                              <img
                                className="img-fluid cmn__siteLogo"
                                src={
                                  // typeof i == "string"
                                  //   ? API_URL + "/cms/" + i
                                  //   : URL.createObjectURL(i)

                                  typeof i == "string"
                                    ? API_URL + "/cms/" + i
                                    : typeof i == "undefined"
                                    ? ""
                                    : URL.createObjectURL(i)
                                }
                              />
                            );
                          })}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={7} className="mb-2 mb-lg-2">
                  <div className="rp_singleinput_holder">
                    <p className="rp_label mb-2 rp_lable_theme">Card Content</p>
                  </div>
                </Col>
              </Row>

              {[...Array(count)].map((item, index) => {
                let i = index;
                return (
                  <>
                    <Row className="align-items-center">
                      <Col lg={7} className="mb-3 mb-lg-4">
                        <div className="rp_singleinput_holder">
                          <p className="rp_label mb-2">Card Heading</p>
                          <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                            <input
                              type="text"
                              placeholder="Card Heading"
                              name="cardHeading"
                              value={formvalue?.cmscard[index]?.cardHeading}
                              className="rp_singleInput flex-grow-1"
                              onChange={(e) => {
                                handleCmscard(e, i);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col lg={7} className="mb-3 mb-lg-4">
                        <div className="rp_singleinput_holder">
                          <p className="rp_label mb-2">Card Content</p>
                          <div className="rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2">
                            <textarea
                              rows={3}
                              type="text"
                              name="cardContent"
                              value={formvalue?.cmscard[index]?.cardContent}
                              placeholder="Card Content"
                              className="rp_singleInput flex-grow-1"
                              onChange={(e) => {
                                handleCmscard(e, i);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <Col lg={7} className="mb-3 mb-lg-4">
                        <div className="rp_singleinput_holder">
                          <p className="rp_label mb-2">Card Image</p>
                          <label
                            for={`file-upload${i}`}
                            className="custom-file-upload mt-3">
                            Custom Upload
                          </label>
                          <input
                            id={`file-upload${i}`}
                            type="file"
                            onChange={(e) => {
                              handleCmscard(e, i);
                            }}
                            name="cardImage"
                            className="hide_input"
                          />
                        </div>
                        <Row className="mt-3">
                          <Col lg={3}>
                            {console.log(
                              formvalue?.cmscard[index],
                              "cmscard[index]?.cardImage"
                            )}
                            <img
                              className="img-fluid cmn__siteLogo"
                              src={
                                formvalue?.cmscard?.length > 0 &&
                                typeof formvalue?.cmscard[index]?.cardImage ==
                                  "string"
                                  ? API_URL +
                                    "/cms/" +
                                    formvalue?.cmscard[index]?.cardImage
                                  : typeof formvalue?.cmscard[index]
                                      ?.cardImage == "undefined"
                                  ? ""
                                  : URL.createObjectURL(
                                      formvalue?.cmscard[index]?.cardImage
                                    )
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                );
              })}

              <Row className="align-items-center">
                <Col lg={5} className="mb-4">
                  <div className="rp_singleinput_holder">
                    <button
                      className="orange_small_primary mt-4"
                      onClick={() => {
                        setCount(count + 1);
                      }}>
                      Add
                    </button>
                    <button
                      className="orange_small_secondary mt-4 ms-3"
                      disabled={count == 1 ? "disabled" : ""}
                      onClick={() => {
                        setCount(count - 1);
                        handleRemove();
                      }}>
                      Delete
                    </button>
                  </div>
                </Col>
              </Row>

              <div className="d-flex gap-3 mb-5">
                <button
                  className="orange_small_primary mt-4"
                  onClick={(e) => {
                    handleSubmit("cancel");
                  }}>
                  Cancel
                </button>
                <button
                  className="orange_small_primary mt-4"
                  onClick={(e) => {
                    handleSubmit("save");
                  }}>
                  Save
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CMSedit;
