import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import supportticket from "../Components/data/supportticket.js";

import DataTable from "react-data-table-component";

import { getAllTikcetList } from "../actions/settings";
import { dateTimeFormat } from "../lib/dateTimeHelper";
import { encrypt } from "../DecryptApi/decryptapi.js";

function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i < pages; i++) {
    results.push(i);
  }

  return results;
}

const columns = [
  {
    name: "Ticket Id",
    selector: (row) => row.tickerId,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Title",
    selector: (row) => row.subject,
    sortable: true,
  },
  {
    name: "Description",
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: "Time",
    selector: (row) => dateTimeFormat(row.createdAt),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => (row.status == "open" ? "Open" : "Closed"),
    sortable: true,
  },
  {
    name: "Action",
    button: true,
    cell: (row) => (
      <div className="App">
        <div class="openbtn text-center">
          {row.status == "open" ? (
            <Link
              to={{
                pathname: "/replyTicket",
                state: { datas: JSON.stringify(row) },
              }}
              class={"btn subs_table_greenBtn"}>
              Reply
            </Link>
          ) : (
            <Link
              to={{
                pathname: "/replyTicket",
                state: { datas: JSON.stringify(row) },
              }}
              class={"btn subs_table_greenBtn"}>
              Open
            </Link>
          )}
        </div>
      </div>
    ),
  },
];
// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav className="d-flex justify-content-end mt-3">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page">
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}>
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page">
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

function SupportTicket() {
  const [userTickets, setUserTickets] = useState();

  const getAllTickets = async () => {
    try {
      let reqData = {
        method : "post"
      };
      let encdata = encrypt(reqData);
      // let reqData = {
      //   page: pageNumer,
      //   limit: limit,
      // };
      let { status, message, result, count } = await getAllTikcetList(encdata);
      if (status == "success") {
        setUserTickets(result);
      }
    } catch {}
  };

  useEffect(() => {
    getAllTickets();
  }, []);

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"Support Ticket"} />
            <div className="common_page_scroller mt-5 pe-2">
              <div className="exchange_table_holder users_table dashboard_box rounded-3 mt-4 p-3">
                <DataTable
                  columns={columns}
                  data={userTickets}
                  defaultSortFieldID={1}
                  pagination
                  paginationComponent={BootyPagination}
                />

                {/* <ReactDatatable 
                  config={config}
                  records={state}
                  columns={columns}
                  extraButtons={extraButtons}
                /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SupportTicket;
