import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import users from "../Components/data/users.js";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import DataTable from "react-data-table-component";
import ConfirmModal from "../Modals/ConfirmModal.js";
import { getUserList } from "../actions/admin";
import toast from "react-hot-toast";
import { encrypt } from "../DecryptApi/decryptapi.js";

function getNumberOfPages(rowCount, rowsPerPage) {
  console.log(
    "🚀 ~ getNumberOfPages ~ rowCount, rowsPerPage:",
    rowCount,
    rowsPerPage
  );
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  console.log("🚀 ~ toPages ~ pages:", pages);
  const results = [];

  for (let i = 1; i <= pages; i++) {
    results.push(i);
  }

  return results;
}

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);

  console.log(pageItems, pageItems.length, currentPage, "pageItems.length");

  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav className="d-flex justify-content-end mt-3">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page">
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}>
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page">
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

// import ReactDatatable from "@ashvin27/react-datatable";

function Userlist() {
  // console.log(state.records[0].status.props,"asdfasfasdfasdf");
  const [showConfirm, setShowConfirm] = useState(false);
  const [blocklist, setBlocklist] = useState("");

  const [userLits, setUserList] = useState();

  const getUserAllList = async () => {
    try {
      let reqData = { method : "post" };
      let data = encrypt(reqData);
      let { status, message, result, count } = await getUserList(data);
      console.log("result data", result)
      if (status == "success") {
        setUserList(result);
      }
    } catch {}
  };

  useEffect(() => {
    getUserAllList();
  }, []);

  const [deleteRecord, setDeleteRecord] = useState({});

  const handleShowConfirm = (record) => {
    setDeleteRecord(record);
    setShowConfirm(true);
  };

  const handleCloseConfirm = (record) => {
    setDeleteRecord({});
    setShowConfirm(false);
  };

  const columns = [
    {
      name: "S.no",
      selector: (row,index) => index + 1,
       sortable: true,
      maxWidth:"40px"
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
      maxWidth:"100px"

    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.Deleted == 0 ? "Active" : "Deactive"),
      sortable: true,
      
    },
    {
      name: "Blocklist",
      button: true,
      cell: (row) => (
        <div className="App">
          <div class="openbtn text-center">
            <button
              type="button"
              class={
                row.Deleted == 0
                  ? "btn subs_table_redBtn"
                  : "btn subs_table_greenBtn"
              }
              onClick={() => {
                handleShowConfirm(row._id);
                setBlocklist(row.Deleted == 0 ? "Disable" : "Enable");
              }}>
              {row.Deleted == 0 ? "Disabled" : "Enable"}
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className="p-0 d-none d-xl-block">
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={"User List"} />

            <div className="common_page_scroller mt-5 pe-2">
              <div className="exchange_table_holder users_table dashboard_box rounded-3 mt-4 p-3">
                <DataTable
                  columns={columns}
                  data={userLits}
                  defaultSortFieldID={1}
                  pagination
                  paginationComponent={BootyPagination}
                />

                {/* <ReactDatatable 
                  config={config}
                  records={state}
                  columns={columns}
                  extraButtons={extraButtons}
                /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          handleClose={handleCloseConfirm}
          blocklist={blocklist}
          record={deleteRecord}
          getUserAllList={getUserAllList}
        />
      )}
    </>
  );
}

export default Userlist;
